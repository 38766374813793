
<template>
    <div>
        <Navbar :page="`Corrigindo avaliação ${ treinamento && treinamento.nome ? treinamento.nome : '' }`"  />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="text-base font-medium">Corrigindo avaliação: {{ treinamento && treinamento.nome ? treinamento.nome : '' }}</h1>
            <h1 class="text-base font-medium my-2">Convidado: {{ convidado && convidado.nome ? convidado.nome : '' }}</h1>
            <div v-if="perguntas" class="mt-4 pb-4">
                <div v-for="(item, index) in perguntas" :key="index" class="grid grid-cols-12 gap-6 my-1 md:my-3 relative">
                    <div class="col-span-12 md:col-span-12">
                        <div class="bg-gray-100 px-4 py-3 rounded-sm">
                            <p class="text-normal text-base"> {{ item.p.pergunta.pergunta }}? </p>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <label class="block text-sm font-medium mb-2">RESPOSTA: </label>
                        <textarea rows="3" type="text" disabled v-model="item.p.resposta_campoaberto" name="pergunta" id="pergunta" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <label class="block text-sm font-medium mb-2">A resposta esta: </label>
                        <label class="inline-flex items-center">
                            <input type="radio" class="form-radio" :name="`RespostaPergunta${index}`" v-model="item.resposta" value="acertou">
                            <span class="ml-2">Correta</span>
                        </label>
                        <label class="inline-flex items-center ml-6">
                            <input type="radio" class="form-radio" :name="`RespostaPergunta${index}`" v-model="item.resposta" value="parcial">
                            <span class="ml-2">Parcialmente correta</span>
                        </label>
                        <label class="inline-flex items-center ml-6">
                            <input type="radio" class="form-radio" :name="`RespostaPergunta${index}`" v-model="item.resposta" value="incorreta">
                            <span class="ml-2">Incorreta</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-2 mt-4">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar corração
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'avaliacoes',
            perguntas: [],
            treinamento: null, 
            convidado: null,
        }
    },
    methods: {
        async start(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            if (id) {
                const req = await this.$http.post(`/v1/${this.route}/getPerguntasCorrigir/`,{ _id: id });
                this.perguntas = req.data.data;
                this.convidado = req.data.convidado;
                this.treinamento = req.data.treinamento;
            }
        },
        async save(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/corrigirProva`, { _id: id, perguntas: this.perguntas }); 
            if (req.data.success) {
                this.$vToastify.success("Corrigido com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
    },
    async beforeMount() {
        this.start();
    },
}
</script>